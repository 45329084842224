//import * as Vue from 'vue'
//import VueRouter from 'vue-router'
import { createWebHistory, createRouter } from "vue-router";
import LoginView from '../views/login'
import { auth } from '../firebase'

//import ScannerView from '../views/console/scanner/scanner_view';

//Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { },
    component: LoginView
  },
  {
    path: '/jodi',
    name: 'Joi',
    meta: { },
    component: () => import(  '../views/jodi')
  },
  {
    path: '/page/:handle',
    name: 'Page',
    meta: { },
    component: () => import(  '../views/page')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { transparentHeader: true },
    component: () => LoginView
  },
  {
    path: '/page/:handle',
    name: 'Page',
    meta: { some_meta_variable: 'whatever' },
    component: ()  => import('../views/page')
  },
  {
    path: '/not-found',
    name: '404',
    component: ()  => import('../views/404')
  },


  {
    name: 'Console',
    path: '/console',
    meta: { requires_auth: true, requires_active_subscription: true },
    component: () => import('../views/console/console'),
    children: [
      {
        path: 'scanner',
        name: 'Wheel Strategy - Put Option Scanner',
        meta: { requires_active_subscription: true },
        component: ()  => import('../views/console/scanner/scanner_view')
      },
      {
        path: 'orders',
        name: 'Trade Management',
        meta: { requires_active_subscription: true },
        component: () => import('../views/console/orders/pending_orders_view'),
      },
      {
        path: 'orders/pending',
        name: 'Trade Management - Trades waiting to be filled by broker',
        meta: { requires_active_subscription: true },
        component: ()  => import('../views/console/orders/pending_orders_view')
      },
      {
        path: 'orders/in_progress',
        name: 'Trade Management - Current Trades & Positions',
        meta: { requires_active_subscription: true },
        component: ()  => import('../views/console/orders/progress_orders_view')
      },
      {
        path: 'orders/completed',
        name: 'Trade Management - Completed Trades',
        meta: { requires_active_subscription: true },
        component: ()  => import('../views/console/orders/completed_orders_view')
      },
      {
        path: 'subscribe',
        name: 'Subscribe',
        meta: { requires_active_subscription: false },
        component: ()  => import('../views/console/plans/plans_view')
      },
      {
        path: 'checkout/:membership_type_id',
        name: 'Checkout',
        meta: { requires_active_subscription: false },
        component: ()  => import('../views/console/checkout/checkout_view')
      },
      {
        path: 'account',
        name: 'Account',
        meta: { requires_active_subscription: false },
        component: ()  => import('../views/console/account/account_dashboard'),
        children: [
          {
            path: '',
            name: 'Profile',
            component: ()  => import('../views/console/account/profile_view')
          },
          {
            path: 'subscriptions',
            name: 'Subscriptions',
            component: ()  => import('../views/console/account/subscriptions_view')
          },
          {
            path: 'subscriptions/:checkout_id',
            name: 'Subscription Item',
            component: ()  => import('../views/console/account/subscriptions_view')
          }
        ]
      }
    ]
  },


  {
    name: 'Admin Dashboard',
    path: '/admin',
    meta: { requiresAuth: true, requires_admin: true },
    component: () => import('../views/admin/dashboard'),
    children: [
      {
        name: 'Members',
        path: 'members',
        meta: { links: [] },
        component: () => import('../views/admin/members/index'),
      },
      {
        name: 'Graphs',
        path: 'graphs',
        meta: { links: [] },
        component: () => import('../views/admin/graphs/index'),
      },
      {
        name: 'Pages',
        path: 'pages',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'All Pages',
            path: '',
            meta: { links: ['/admin/pages/new'] },
            component: () => import('../views/admin/pages/index')
          },
          {
            name: 'New Page',
            path: 'new',
            meta: { links: ['/admin/pages'], is_wide_view: true },
            component: () => import('../views/admin/pages/edit')
          },
          {
            name: 'Edit Page',
            path: ':id',
            meta: { links: ['/admin/pages'], is_wide_view: true },
            component: () => import('../views/admin/pages/edit')
          }
        ]
      },
      {
        name: 'Navigation',
        path: 'navigation',
        component: () => import('../views/admin/view_group'),
        children: [
          {
            name: 'Navigation Lists',
            path: '',
            meta: { links: ['/admin/navigation/new'] },
            component: () => import('../views/admin/navigation/index')
          },
          {
            name: 'Edit Navigation List',
            path: ':id',
            meta: { links: ['/admin/navigation'] },
            component: () => import('../views/admin/navigation/edit')
          }
        ]
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/*
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});*/

router.beforeEach((to, from, next) => {
 // window.scrollTo(0, 0);
 /* const requires_auth = to.matched.some(x => x.meta.requires_auth);

  console.log("route", to.path, requires_auth, router.$store.getters.auth_session);

  //console.log("auth.currentUser", auth.currentUser ? auth.currentUser.email : "no user");

  if (!requires_auth) {
    next();
  }else if(router.$store.getters.auth_session && router.$store.getters.auth_session.user) {
    next();

    //auth.currentUser.getIdTokenResult().then(token => {
    //  if (!token.claims.admin){
    //   next('/login');
    // }
    //})
  } else {
    next(`/login?destination=${to.path}`);
  }*/


  const requires_auth = to.matched.some(x => x.meta.requires_auth);
  const requires_admin = to.matched.some(x => x.meta.requires_admin);

   console.log("route", { name:to.name, path: to.fullPath, requires_auth: requires_auth, requires_admin: requires_admin, auth_session: router.$store.getters.auth_session });

  // next(); return; // for debug

  if (!requires_auth && !requires_admin) {

    // console.log("next");
    next();

  } else if (requires_admin && router.$store.getters.auth_session &&
      router.$store.getters.auth_session.user &&
      router.$store.getters.auth_session.user.app_metadata.is_admin){
    next();

  } else if(!requires_admin && requires_auth && router.$store.getters.auth_session &&
      router.$store.getters.auth_session.user) {
    next();

  } else {
    next(`/login?destination=${to.path}`);

  }

});

export default router
